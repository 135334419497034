iframe {
  width: 100%;
  border: none;
}

h2 {
  text-align: center;
}

.booking-tool iframe {
  min-height: 175px;

  @media (max-width: 1501px) {
    min-height: 205px;
  }
}

.bmg-review-widget .review-widget {
  width: 100% !important;
  margin: 10px auto auto;
  display: block;

  .review-widget-wrapper .main-content .green {
    color: #0b8010 !important;
  }
}

@-webkit-keyframes hvrpulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvrpulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.motchecker,
#booknowcta-container a p,
.hom-box-btn {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;

  &:hover,
  &:focus,
  &:active {
    -webkit-animation-name: hvrpulse;
    animation-name: hvrpulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

.googlemap {
  height: 480px;
  margin-bottom: 20px;
}

.contactinformation {
  svg {
    fill: #fff;
    width: 16px;
    height: 16px;
  }

  p {
    color: #fff;
  }

  a:hover {
    text-decoration: none;
  }

  .booking-tool {
    background-color: $primary-color;
    // border: 2px solid $primary-color;
    border-radius: 20px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    h2,
    p {
      color: #fff;
    }
  }
}

#bookonlinepage {
  h1,
  h2,
  p {
    text-align: center;
  }

  .booking-tool {
    h2,
    p {
      color: $primary-color
    }
  }

  img {
    background: $primary-color;
  }
}

#bookonlinepage,
#inner-page,
#contactpage {
  background-color: $secondary-color;
  color: #fff;
  padding-top: 25px;

  @media (max-width:767px) {
    padding-top: 15px;
  }
}

.cover {
  width:100%;
  height: 100%;
  border-radius: 25px;
  cursor: pointer;
  border: 12px solid #fff;
}

.playbtn {
  height: 60px;
  cursor: pointer;
  opacity: 0.9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d( -50%, -50%, 0 );
}

.innerpage-youtube {
	width: 600px;
	height: 300px;
  border: 10px solid $primary-color;
	margin: auto;
	display: block;
  border-radius: 13px;
  padding: 0;

  @media (max-width:991px) {
    width: 100%;
    height: 180px;
    margin-bottom: 10px;
  }
}

.youtube {
  max-width: 100%;
  width: 600px;
  height: 300px;
  border: 10px solid $primary-color;
  margin: auto;
  display: block;
  border-radius: 13px;
  padding: 0;
}